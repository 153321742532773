import React from "react";
import "./index.less";
import axios from "@utils/httpSSR";

export default props => {
    const logout = async () => {
        if (props.logout && typeof props.logout == "function") {
            props.logout();
            return;
        }
        const res = await axios({
            method: "get",
            url: "/api/user/logout"
        });
        if (res.url) {
            window.location = `${res.url}/logout?logout_success_url=${window.location.origin}`;
        } else {
            console.log("退出失败");
        }
    };

    return (
        <div id='mh-header' styleName='header'>
            <div styleName='left'>
                <img
                    onClick={() => {
                        location.href = "/";
                    }}
                    style={{ cursor: "pointer" }}
                    styleName='logo'
                    src={require("@assets/common/logo.png")}
                />
                <span styleName='client-name'>{props.title}</span>
            </div>
            <div styleName='right'>
                <img styleName='logo' width={25} src={require("@assets/common/user.png")} />
                <span styleName='user-name'>{props.mobile}</span>
                <a styleName='logout' onClick={() => logout()}>
                    安全退出
                </a>
            </div>
        </div>
    );
};
