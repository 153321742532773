import React from "react";
import { Carousel } from "antd";
import "./index.less";
export default props => {
    const { child } = props;
    return (
        <div styleName='carousel-warp'>
            <Carousel autoplay>
                {child.map((v, i) => {
                    return (
                        <div key={i} styleName='carousel-item'>
                            <img src={v.img} />
                            <div styleName='text'>
                                {v.text.map((t, i) => {
                                    return (
                                        <p key={t} styleName={`text-${i + 1}`}>
                                            {t}
                                        </p>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
};
