import React, { useEffect, useState } from "react";
import { Menu, message } from "antd";
import axios from "axios";
import "./index.less";

const findRouter = (routers, key) => {
    let out = null;
    for (let i = 0, len = routers.length; i < len; i++) {
        if (routers[i].link == key) {
            out = routers[i];
            if (out) {
                return out;
            }
        }
        if (routers[i].children) {
            out = findRouter(routers[i].children, key);
            if (out) {
                return out;
            }
        }
    }
};

export default props => {
    const { router } = props;
    const [drawData, setDrawData] = useState([...router[2].children]);
    const [active, setActive] = useState(0);
    const [menuActive, setMenuActive] = useState("");
    const [env, setEnv] = useState("dev");

    useEffect(() => {
        let target = findRouter(router, window.location.pathname);
        if (!target) {
            return;
        }
        if (target.activePath) {
            target = findRouter(router, target.activePath);
        }
        setMenuActive(target.title);
        (async () => {
            const res = await axios.get("/api/sys/cfg");
            setEnv(res.data?.ENC);
        })();
    }, []);

    const link = (path, target) => {
        if (!path) {
            return message.info({ icon: <></>, content: "敬请期待" });
        }
        if (path.toString() == "[object Object]") {
            return target ? window.open(path[env]) : (window.location = path[env]);
        }
        target ? window.open(path) : (window.location = path);
    };

    const renderMenu = (menu, sub = false) => {
        if (!menu.children) {
            return (
                <Menu.Item
                    onClick={() => link(menu.link)}
                    key={menu.title}
                    onMouseEnter={() => onOpenChange([menu.title])}
                    onMouseLeave={() => onOpenChange([])}
                >
                    {sub ? <p styleName='sub-menu-text'>{menu.title}</p> : menu.title}
                </Menu.Item>
            );
        }
        if (menu.children && !menu.draw) {
            return (
                <Menu.SubMenu
                    title={menu.title}
                    key={menu.title}
                    onTitleClick={() => {
                        link(menu.link);
                    }}
                >
                    {menu.children.map(m => {
                        return renderMenu(m, true);
                    })}
                </Menu.SubMenu>
            );
        }
        if (menu.draw) {
            return (
                <Menu.SubMenu
                    onTitleClick={() => {
                        link(menu.link);
                    }}
                    title={menu.title}
                    key={menu.title}
                >
                    <Menu.Item
                        key={menu.title}
                        styleName='draw-warp'
                        onMouseDown={e => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                    >
                        <div styleName='draw-content'>
                            <div styleName='left'>
                                {drawData.map((item, idx) => {
                                    return (
                                        <p styleName='menu-left' key={idx} onMouseEnter={() => setActive(idx)}>
                                            {item.title}
                                        </p>
                                    );
                                })}
                            </div>
                            <div styleName='right'>
                                {drawData[active].children.map(v => {
                                    return (
                                        <div styleName='colum' key={v.title}>
                                            <p
                                                styleName='right-title'
                                                onClick={() => {
                                                    link(v.link, v.target);
                                                }}
                                            >
                                                {v.title}
                                            </p>
                                            {v.children &&
                                                v.children?.length > 0 &&
                                                v.children.map(c => {
                                                    return (
                                                        <p
                                                            key={c.title}
                                                            styleName='right-menu-item'
                                                            onClick={() => {
                                                                link(c.link);
                                                            }}
                                                        >
                                                            {c.title}
                                                        </p>
                                                    );
                                                })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Menu.Item>
                </Menu.SubMenu>
            );
        }
    };

    const onOpenChange = e => {
        const target = router.find(v => v.title == e[0]);

        if (!target) {
            return;
        }
        if (target.draw) {
            document.querySelector("");
            setDrawData(target.children);
        }
    };
    return (
        <div styleName='menu-warp'>
            <Menu selectedKeys={[menuActive]} mode='horizontal' styleName='c-menu' onOpenChange={onOpenChange}>
                {router.map(r => {
                    return renderMenu(r);
                })}
            </Menu>
        </div>
    );
};
