import React from "react";
import "./index.less";
export default props => {
    return ENV_IS_NODE ? (
        <html>
            <head>
                <title>东信金科官网</title>
                <meta charSet='utf-8'></meta>
                <meta name='renderer' content='webkit'></meta>
                <meta
                    name='viewport'
                    content='initial-scale=1, maximum-scale=1, user-scalable=no, minimal-ui'
                ></meta>
                <meta name='keywords' content=''></meta>
                <meta name='description' content=''></meta>
                <link
                    rel='shortcut icon'
                    href='/ssr/mh/dist/favicon.ico'
                    type='image/x-icon'
                ></link>
            </head>
            <body>
                <h1 style={{ display: "none" }}>{``}</h1>
                <div id='app' styleName='app'>
                    {props.children}
                </div>
            </body>
        </html>
    ) : (
        props.children
    );
};
