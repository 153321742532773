export default [
    { title: "首页", link: "/", activePath: "/" },
    {
        title: "解决方案",
        link: "/solution",
        children: [
            {
                title: "供应链金融解决方案",
                link: "/solution/yry",
                activePath: "/solution"
            },
            {
                title: "整体数字支付解决方案",
                link: "",
                activePath: "/solution"
            },
            {
                title: "征信服务解决方案",
                link: "/solution/yak",
                activePath: "/solution"
            }
        ]
    },
    {
        title: "产品中心",
        link: "/product",
        draw: true,
        children: [
            {
                title: "征信云", // -企业征信
                link: "",
                children: [
                    {
                        title: "信用报告",
                        link: "/product/report",
                        activePath: "/product",
                        children: []
                    },
                    {
                        title: "跨境征信",
                        link: {
                            dev: "http://cdm.dev.linkfin.caih.local/",
                            test: "http://cdm.test.linkfin.caih.local/",
                            uat: "http://cdm.uat.linkfin.caih.local/",
                            pro: "https://cdm.linkfinance.cn/"
                        },
                        target: "_blank",
                        children: []
                        // children: [
                        //     {
                        //         title: "国内企业征信查询",
                        //         link: {
                        //             dev: "http://lhzx.dev.linkfin.caih.local/search/domestic",
                        //             test: "http://lhzx.test.linkfin.caih.local/search/domestic",
                        //             uat: "http://lhzx.uat.linkfin.caih.local/search/domestic",
                        //             pro: "https://lhzx.linkfinance.cn/search/domestic"
                        //         }
                        //     },
                        //     {
                        //         title: "查东盟",
                        //         link: {
                        //             dev: "http://cdm.dev.linkfin.caih.local/",
                        //             test: "http://cdm.test.linkfin.caih.local/",
                        //             uat: "http://cdm.uat.linkfin.caih.local/",
                        //             pro: "https://cdm.linkfinance.cn/"
                        //         }
                        //     }
                        // ]
                    }
                    // {
                    //     title: "精准营销",
                    //     link: "",
                    //     children: []
                    // }
                ]
            },
            {
                title: "资管云", // -数字支付
                link: "",
                children: [
                    {
                        title: "薪智捷",
                        link: "/product/xzj",
                        activePath: "/product",
                        children: [
                            // {
                            //     title: "薪智捷发薪平台",
                            //     link: "/product/xzj",
                            //     activePath: "/product"
                            // }
                        ]
                    }
                    // {
                    //     title: "金管家",
                    //     link: "/product/jgj",
                    //     children: [
                    //         {
                    //             title: "金管家",
                    //             link: "/product/jgj",
                    //             activePath: "/product"
                    //         }
                    //     ]
                    // },
                    // {
                    //     title: "慧收付",
                    //     link: "",
                    //     children: []
                    // }
                ]
            },
            {
                title: "易融云", // -数字金融
                link: "",
                children: [
                    {
                        title: "供应链金融平台",
                        link: "/product/exin",
                        activePath: "/product",
                        children: [
                            // {
                            //     title: "薪资保理",
                            //     link: "/product/xzbl",
                            //     activePath: "/product"
                            // },
                            // {
                            //     title: "e信平台",
                            //     link: "/product/exin",
                            //     activePath: "/product"
                            // }
                        ]
                    },
                    {
                        title: "电子账户",
                        link: {
                            dev: "http://eac.yry.dev.linkfin.caih.local",
                            test: "http://eac.yry.sit.linkfin.caih.local",
                            uat: "http://eac.yry.uat.linkfin.caih.local",
                            pro: "http://eac.yry.caih.com"
                        },
                        activePath: "/product",
                        children: [
                            // {
                            //     title: "保理云平台",
                            //     link: "/product/bly",
                            //     activePath: "/product"
                            // },
                            // {
                            //     title: "电子账户",
                            //     link: "",
                            //     activePath: "/product"
                            // }
                        ]
                    },
                    {
                        title: "资产证券化服务",
                        link: "/product/eabs",
                        activePath: "/product",
                        children: [
                            // {
                            //     title: "ABS系统",
                            //     link: "/product/eabs",
                            //     activePath: "/product"
                            // }
                        ]
                    }
                ]
            }
        ]
    },
    { title: "新闻动态", link: "" },
    { title: "关于我们", link: "" }
];
