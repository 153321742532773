import axios from "axios";
import { Message } from "antd";
import Cookie from "js-cookie";

const Axios = axios.create({
    baseURL: ""
});
// 请求拦截
Axios.interceptors.request.use(config => {
    if (config.method.toLocaleLowerCase() === "get") {
        config.params = Object.assign({}, config.params, {
            t: new Date().getTime()
        });
    } else {
        config.headers["X-CSRF"] = Cookie.get("x-csrf");
    }
    return config;
});

// 响应拦截
Axios.interceptors.response.use(
    response => {
        const { data, config } = response;
        data.msg && Message.success(data.msg);
        return data;
    },
    err => {
        err.response.data.message && Message.error(err.response.data.message);
        throw {
            status: err.response.status,
            message: err.response.data.message
        };
    }
);

export default Axios;
