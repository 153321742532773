import React, { useEffect, useState } from "react";
import Html from "./default.js";
import Header from "../portalHeader";
import Footer from "../footer";
import { userInfo } from "@http/user";
export default props => {
    const [user, setUserInfo] = useState({});
    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let res = await userInfo();
        setUserInfo(res.data?.userInfo);
    };

    return (
        <Html {...props}>
            <Header {...props} userInfo={user || {}} />
            <main>{props.children}</main>
            <Footer {...props} />
        </Html>
    );
};
