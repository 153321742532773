import React from "react";
import PortalMenu from "./../portalMenu";
import { message } from "antd";
import "./index.less";

message.config({
    getContainer: () => {
        return document.querySelector("#mh-header");
    }
});

const Header = props => {
    const { mobile, financial, user_status, mh_sel } = props.userInfo || {};

    let link = financial || !user_status?.comps.length || mh_sel ? "/admin/qk" : "/loginTp";

    if (!user_status?.user_status) {
        link = "/result?resTp=7";
    }

    return (
        <div styleName='header-warp'>
            <div styleName='header-container'>
                <div styleName='left'>
                    <img
                        onClick={() => {
                            location.href = "/";
                        }}
                        style={{ cursor: "pointer" }}
                        src={require("@assets/mh/logo.png")}
                        alt=''
                    />
                    <PortalMenu {...props} />
                </div>
                <div styleName='right'>
                    {mobile ? (
                        <>
                            <a styleName='client' href={link}>
                                <img src={require("@assets/mh/client.png")} /> 工作台
                            </a>
                            <span
                                style={{
                                    marginRight: "8px",
                                    fontSize: "16px",
                                    marginRight: "8px"
                                }}
                            >
                                {mobile}
                            </span>
                            <div styleName='btn-warp'>
                                <div
                                    onClick={() => {
                                        window.location.href = "/user/logout";
                                    }}
                                    styleName='login'
                                >
                                    退出
                                </div>
                            </div>
                        </>
                    ) : (
                        <div styleName='btn-warp'>
                            <div
                                onClick={() => {
                                    window.location.href = "/user/login";
                                }}
                                styleName='login'
                            >
                                登录
                            </div>
                            <div
                                onClick={() => {
                                    window.location.href = "/user/login";
                                }}
                                styleName='register'
                            >
                                注册
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;
